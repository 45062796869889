import { Switch, Text } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  nullable?: boolean;
  size?: any;
  position?: "right" | "left";
};

export function SwitchField({ name, label, disabled = false, nullable, size, position }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Switch
      label={label}
      disabled={disabled}
      error={error}
      size={size ? size : "lg"}
      labelPosition={position}
      checked={value}
      onLabel={<Text fz={10}>тийм</Text>}
      offLabel={<Text fz={10}>үгүй</Text>}
      onChange={(e) => onChange(e.currentTarget.checked)}
      required={nullable ? false : true}
    />
  );
}
