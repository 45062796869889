import { Flex, Group, Text, Textarea } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  required?: boolean;
  max?: number;
  minRows?: number;
  maxRows?: number;
  h?: number;
  w?: number;
};

export function TextareaField({ name, label, placeholder, disabled = false, required = false, max, maxRows, minRows, h, w }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <Textarea
        w={w}
        h={h}
        maxLength={max}
        minRows={minRows}
        maxRows={maxRows}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        withAsterisk
      />
      <Group align="center" mt={"5px"}>
        {error && (
          <Text c="red" fz={"xs"}>
            {error}
          </Text>
        )}
        {max && (
          <Flex align="center" gap={5}>
            <Text c="dimmed" fz={"xs"}>
              / {max}
            </Text>
          </Flex>
        )}
      </Group>
    </>
  );
}
