import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: any) => {
  return httpRequest.post("/prt/business_sector", data);
};

export const select = async (data: any) => {
  return httpRequest.get("/prt/business_sector/select", data);
};

export const get = async (id: string, data: any) => {
  return httpRequest.get(`/prt/business_sector/${id}`, data);
};
