import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: any) => {
  return httpRequest.post("/prt/branch", data);
};

export const list = async (data: any) => {
  return httpRequest.get("/prt/branch", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/prt/branch/${id}`, data);
};

export const updateAddress = async (id: string, data: any) => {
  return httpRequest.put(`/prt/branch/${id}/address`, data);
};

export const roleSelect = async (data: any) => {
  return httpRequest.get("/prt/role/select", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/prt/branch/${id}`);
};
