import { Image, LoadingOverlay, Modal } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useRef, useState } from "react";
import useSwr from "swr";
import { GeneralApi } from "../../apis";

import BackgroundPattern from "../../assets/bg-pattern.png";
import LoginImage from "../../assets/login-svg.svg";
import { RegisterForm } from "../../components/partner-public/register-form";
import RegisterLayout from "../../components/register-layout/register-layout";
import { useScreenDetector } from "../../components/screen-detector";
import VerifyPage from "./verify";

const Register = () => {
  const [action, setAction] = useState<any[]>([]);
  const { classes } = useStyles();
  const refGeneral = useRef<any>(null);
  const { isTablet } = useScreenDetector();

  const { data: initData, isLoading } = useSwr(`pub/init/general`, async () => await GeneralApi.initPub(), {
    fallbackData: {
      partnerTypes: [],
    },
  });

  if (isLoading) {
    return <LoadingOverlay visible />;
  } else
    return (
      <RegisterLayout>
        <div className={classes.container}>
          {!isTablet && (
            <div className={classes.image}>
              <Image src={LoginImage} alt={"svg"} />
            </div>
          )}
          <div className={classes.card}>
            <img className={classes.bgBlur} src={BackgroundPattern} alt="" />
            <div className={classes.content}>
              <div>
                <div className={classes.loginText}>Бүртгүүлэх</div>
                <RegisterForm ref={refGeneral} initData={initData} setAction={setAction} />
              </div>
            </div>
          </div>
        </div>

        <Modal size={750} title={false} withCloseButton={false} centered opened={action[0] === "verify"} onClose={() => {}} padding={0}>
          <VerifyPage action={action} setAction={setAction} />
        </Modal>
      </RegisterLayout>
    );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 40,
    padding: "4rem",
    [`@media (max-width: 570px)`]: {
      padding: "1rem",
    },
  },
  card: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff09",
    flexWrap: "wrap",
    maxWidth: "520px",
  },
  content: {
    zIndex: 1,
    display: "flex",
    paddingInline: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    paddingTop: 35,
    paddingBottom: 35,
  },
  bgBlur: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: theme.white,
    fontSize: 16,
    fontWeight: 400,
  },
  loginText: {
    marginTop: 15,
    fontSize: 40,
    color: theme.white,
    fontWeight: 800,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  info: {
    display: "flex",
    color: "white",
  },
}));

export { Register };
