import { LoadingOverlay } from "@mantine/core";
import qs from "qs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AuthApi, GeneralApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { authChange, authMe } from "../../store/Auth";
import { initGeneral } from "../../store/General";

export function Access() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  React.useEffect(() => {
    (async () => {
      try {
        const params = qs.parse(location.search);
        dispatch(authChange({ accessToken: params["?token"] } as any));

        if (accessToken) {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));

          const auth = await AuthApi.me();
          dispatch(authMe(auth));
          navigate("/organization");
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [accessToken, dispatch, location, navigate]);

  return <LoadingOverlay visible />;
}
