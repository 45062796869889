import { Box, Button, FileButton, Image, LoadingOverlay, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconCheck, IconTrash, IconUpload } from "@tabler/icons-react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { MediaApi } from "../../apis";

export type IFile = {
  uid: string;
  uploading: boolean;
  url?: string;
  selected: boolean;
  file: Blob;
};

type Props = {
  onChange?: (files: IFile[]) => void;
  max?: number;
  initialData?: any;
};

export function MultiImageUpload({ onChange, max, initialData }: Props) {
  const { classes, cx } = useStyle();
  const resetRef = React.useRef<() => void>(null);
  const [files, setFiles] = React.useState<{ [key: string]: IFile }>({
    ...(initialData || [])?.map((c: any, index: number) => ({ url: c.url, uid: `${index}`, uploading: false, selected: false })),
  });

  const onFileUpload = (blobs: Blob[]) => {
    const uploads = blobs.map((blob) => {
      const uid = uuidv4();

      (async () => {
        try {
          const form = new FormData();
          form.append("file", blob);

          const res = await MediaApi.uploadImage(form);

          setFiles((files) => {
            const changes = {
              ...files,
              [uid]: {
                ...files[uid],
                uploading: false,
                url: res.url,
                uid: uid,
              },
            };

            onChange && onChange(Object.values(changes));

            return changes;
          });
        } catch (err: any) {
          console.log(err);
          if (err?.statusCode === 413) {
          }
        }
      })();

      return {
        uid: uid,
        file: blob,
        selected: false,
        uploading: true,
      };
    });

    const changes = {
      ...files,
      ...uploads.reduce((accumulator, iterator) => ({ ...accumulator, [iterator.uid]: iterator }), {}),
    };

    setFiles(changes);

    if (onChange) onChange(Object.values(changes));

    resetRef.current?.();
  };

  const onRemove = (uid: string) => {
    const changes = Object.keys(files).reduce((accumulator, iterator) => {
      if (uid === iterator) return accumulator;

      return {
        ...accumulator,
        [iterator]: files[iterator],
      };
    }, {});

    setFiles(changes);

    resetRef.current?.();

    if (onChange) onChange(Object.values(changes));
  };

  const onSelect = (uid: string) => {
    const changes = Object.keys(files).reduce((accumulator, iterator) => {
      return {
        ...accumulator,
        [iterator]: {
          ...files[iterator],
          selected: uid === iterator ? true : false,
        },
      };
    }, {});

    setFiles(changes);

    if (onChange) onChange(Object.values(changes));
  };

  return (
    <div className={classes.multiImageUpload}>
      {Object.keys(files).map((key, index) => {
        const file = files[key];

        return (
          <Box className={cx(classes.imageBox, file.selected && "selected")} key={index}>
            <Image className={classes.image} width={80} height={80} src={file.url || URL.createObjectURL(file.file)} alt="" />
            <LoadingOverlay visible={file.uploading} opacity={0.3} loaderProps={{ size: "sm" }} />
            {!file.uploading && (
              <div className={classes.actionBox}>
                <UnstyledButton className={classes.actionButton} onClick={() => onSelect(file.uid)}>
                  <IconCheck size={20} className={classes.iconCheck} />
                </UnstyledButton>
                <UnstyledButton className={classes.actionButton} onClick={() => onRemove(file.uid)}>
                  <IconTrash size={20} className={classes.iconTrash} />
                </UnstyledButton>
              </div>
            )}
          </Box>
        );
      })}

      <FileButton disabled={max ? Object.keys(files).length === max : false} resetRef={resetRef} onChange={onFileUpload} accept="image/png,image/jpeg" multiple>
        {(props) => (
          <Button disabled={max ? Object.keys(files).length === max : false} variant="light" className={classes.uploadButton} {...props}>
            <IconUpload size={20} />
          </Button>
        )}
      </FileButton>
    </div>
  );
}

const useStyle = createStyles((theme, _params, u) => ({
  multiImageUpload: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
  },
  imageBox: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    cursor: "pointer",
    borderRadius: 4,
    overflow: "hidden",
    "&.selected": {
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: theme.colors.indigo[6],
    },
    [`&:hover .${u.ref("actionBox")}`]: {
      display: "flex!important",
    },
  },
  image: {
    position: "absolute",
  },
  uploadButton: {
    width: 80,
    height: 80,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  actionBox: {
    ref: u.ref("actionBox"),
    display: "none",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1011135c",
    transition: "show 150ms ease, transform 100ms ease",
    width: 80,
    height: 80,
    zIndex: 10,
    gap: 5,
  },
  actionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 26,
    height: 26,
    borderRadius: 4,
    color: theme.colors.gray[0],
    borderColor: theme.colors.gray[0],
    borderWidth: 0,
    borderStyle: "solid",
    transition: "all 150ms ease, transform 100ms ease",
    [`&:hover .${u.ref("iconCheck")}`]: {
      color: theme.colors.indigo[6],
    },
    [`&:hover .${u.ref("iconTrash")}`]: {
      color: theme.colors.red[6],
    },
  },
  iconCheck: {
    ref: u.ref("iconCheck"),
  },
  iconTrash: {
    ref: u.ref("iconTrash"),
  },
  actionIcon: {},
}));
