import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/prt/auth/me");
  return res;
};

export const me2fa = async () => {
  const res = await httpRequest.get("/2fa/auth/me");
  return res;
};

export const createPin = async (data: any) => {
  const res = await httpRequest.post("/2fa/auth/pin", data);
  return res;
};

export const updatePin = async (data: any) => {
  const res = await httpRequest.put("/2fa/auth/pin", data);
  return res;
};

export const updateProfile = async (data: any) => {
  const res = await httpRequest.put("/prt/auth/profile", data);
  return res;
};

export const updateAvatar = async (data: any) => {
  const res = await httpRequest.put("/prt/auth/avatar", data);
  return res;
};

export const changePassword = async (data: any) => {
  const res = await httpRequest.post("/2fa/auth/password/change", data);
  return res;
};
