import { IFilter } from "../interfaces/IFilter";
import { IPartner } from "../interfaces/IPartner";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/prt/contract_doc", { offset, filter });
};

export const remove = async (id: string) => {
  return httpRequest.del(`/prt/contract_doc/${id}`);
};

export const update = async (id: string, data: IPartner | any) => {
  return httpRequest.put(`/prt/contract_doc/${id}`, data);
};

export const create = async (data: IPartner | any) => {
  return httpRequest.post(`/prt/contract_doc`, data);
};
