import { createStyles } from "@mantine/emotion";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

export function Dashboard() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/") && !location.pathname.includes("/organization")) {
      navigate("/organization", { replace: true });
    }
  }, [location, navigate]);

  return <div className={classes.root}></div>;
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: `${theme.spacing.xl} * 1.5`,
  },
}));
