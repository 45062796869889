import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const updateInfo = async (data: any) => {
  return httpRequest.put("/prt/partner", data);
};

export const list = async (data: any) => {
  return httpRequest.get("/prt/partner", data);
};

export const updateAdmin = async (data: any) => {
  return httpRequest.put("/prt/partner/update_admin", data);
};

export const roleSelect = async (data: any) => {
  return httpRequest.get("/prt/role/select", data);
};

export const uploadLogo = async (id: string, data: any) => {
  return httpRequest.put(`/prt/partner/${id}/logo`, data);
};

export const uploadLogoProfile = async (data: any) => httpRequest.put(`/prt/partner/logo`, data);
