import { Group, Radio } from "@mantine/core";
import { useField } from ".";

type IOptions = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  label?: any;
  labelPosition?: "right" | "left";
  required?: boolean;
  options: IOptions[];
  className?: any;
  size?: any;
};

export function CheckboxRadioField({ name, label, labelPosition, required = false, options, className, size }: Props) {
  const { value, onChange } = useField(name);

  return (
    <Radio.Group
      size={size}
      value={value}
      onChange={(e) => {
        onChange(e);
      }}
      label={label}
      required={required}>
      <Group mt="sm">
        {options.map((item: IOptions, index: number) => {
          return <Radio value={item.value} labelPosition={labelPosition} label={item.label} key={index} />;
        })}
      </Group>
    </Radio.Group>
  );
}
