import { Group, Radio } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  onChange?: (value: string | null) => void;
  options: { value: string; label: string }[];
  defaultValue?: string;
};

export function RadioGroupField({ name, label, onChange: onChangeValue, options = [], defaultValue }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Radio.Group
      label={label}
      value={value}
      onChange={(value) => {
        onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      required
      style={{ flex: 1 }}
      defaultValue={defaultValue}>
      <Group mt="sm">
        {options.map((v: any, index: number) => (
          <Radio key={index} value={v.value} label={v.label} />
        ))}
      </Group>
    </Radio.Group>
  );
}
