import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  return httpRequest.get("/prt/warehouse", data);
};

export const create = async (data: any) => {
  return httpRequest.post("/prt/warehouse", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/prt/warehouse/${id}`, data);
};

export const del = async (id: string) => {
  return httpRequest.del(`/prt/warehouse/${id}`);
};
