import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { Layout } from "./components/layout";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import { Register } from "./pages/auth/register";
import { Buyer } from "./pages/buyer";
import { Dashboard } from "./pages/dashboard";
import { NotFound } from "./pages/not-found";
import { Partner } from "./pages/partner";
import { Sector } from "./pages/sectors";
import { Supplier } from "./pages/supplier";
import { Warehouse } from "./pages/warehouse";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));

          const auth = await AuthApi.me();
          dispatch(authMe(auth));
        }
        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/organization" element={<Partner />} />
        <Route path="/our-supplier" element={<Supplier type="SUPPLIER" />} />
        <Route path="/our-buyer" element={<Buyer type="BUYER" />} />
        <Route path="/our-sectors" element={<Sector />} />
        <Route path="/our-warehouse" element={<Warehouse />} />
        <Route path="/access" element={<Access />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
