import { Box, Button, Divider, Flex, Grid, Image, Input, LoadingOverlay, Text } from "@mantine/core";
import { IconChevronsRight, IconPhoto } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { BranchApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { ImageField } from "../form/image-field";
import { MultiSelectField } from "../form/multi-select-field";
import { RadioGroupField } from "../form/radiogroup-field";
import { SelectField } from "../form/select-field";
import { SwitchField } from "../form/switch-field";
import { TextField } from "../form/text-field";
import { MultiImageUpload } from "../upload/multi-image-upload";

type IFormData = {
  name: undefined;
  nameEng: string | undefined;
  profileBanners: any | undefined;
  logo: string | undefined;
  type: string | undefined;
  isDefault: boolean | string | undefined;
  isBuyer: boolean | string | undefined;
  isSupplier: boolean | string | undefined;
  buyers: any;
  suppliers: any;
  branchStatus: any;
};

const schema = yup.object({
  name: yup
    .string()
    .matches(/^[А-Яа-я0-9|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  nameEng: yup
    .string()
    .matches(/^[A-Za-z0-9\s-.]{2,256}$/, "Зөвхөн латин үсэг авна")
    .required("Заавал бөглөнө!"),
  type: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  profileBanners: yup.array().nullable(),
  logo: yup.string().nullable(),
  isDefault: yup.boolean().nullable(),
  isBuyer: yup.boolean().nullable(),
  isSupplier: yup.boolean().nullable(),
  buyers: yup.array().when("isBuyer", (isBuyer, field) => (isBuyer ? field.required("Заавал бөглөнө!") : field)),
  suppliers: yup.array().when("isSupplier", (isSupplier, field) => (isSupplier ? field.required("Заавал бөглөнө!") : field)),
  branchStatus: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

export function SectorForm({ onClose, action, reload, setId, setActiveTab }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { branchTypes, businesses } = useSelector((state: { general: IGeneral }) => state.general);
  const formRef = useRef<IFormRef<{ submit: any }>>(null);

  const [data, setFormData] = React.useState<IFormData>({
    name: undefined,
    nameEng: undefined,
    profileBanners: [],
    logo: undefined,
    type: undefined,
    isDefault: false,
    isBuyer: "false",
    isSupplier: "false",
    branchStatus: "INACTIVE",
    buyers: [],
    suppliers: [],
    ...(action && action[1]
      ? {
          name: action[1].name,
          nameEng: action[1]?.nameEng || undefined,
          profileBanners: action[1]?.profileBanners.map((c: any) => ({ url: c.url, isMain: c.isMain ?? false })) ?? [],
          logo: action[1].logo,
          type: action[1].type,
          isDefault: action[1].isDefault,
          isBuyer: action[1]?.isBuyer?.toString(),
          isSupplier: action[1]?.isSupplier?.toString(),
          buyers: action[1]?.buyers?.map((c: any) => c.id),
          suppliers: action[1]?.suppliers?.map((c: any) => c.id),
          branchStatus: action[1].branchStatus,
        }
      : null),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    console.log("VALUES: ", values);
    try {
      const formData = {
        ...values,
        isDefault: Boolean(values?.isDefault),
        isBuyer: values?.isBuyer === "true",
        isSupplier: values?.isSupplier === "true",
        profileBanners: values?.profileBanners || [],
        branchStatus: values?.branchStatus ?? "INACTIVE",
      };

      if (action && action[1]) {
        await BranchApi.update(action[1]?.id, formData);
      } else {
        const res = await BranchApi.create({ ...formData, branchStatus: "ACTIVE" });
        setId(res?.id);
      }
      message.success("Таны хүсэлт амжилттай!");
      setLoading(false);
      await reload();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message!);
    }
  };

  return (
    <Form ref={formRef} initialValues={data} validationSchema={schema} onSubmit={(e) => e}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="NETWORK-Д ИЛ ХАРАГДАХ МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <TextField name="name" label="Салбарын нэр:" placeholder="Салбарын нэр оруулна уу" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <TextField name="nameEng" label="Салбарын нэр /Latin, English/:" placeholder="Салбарын нэр /Latin, English/" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <SelectField
                    placeholder="Салбарын төрөл:"
                    options={branchTypes.map((c: any) => ({ label: c.name, value: c.code }))}
                    name="type"
                    label="Салбарын төрөл"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <SelectField
                    placeholder="Салбарын төлөв:"
                    options={[
                      {
                        label: "Идэвхтэй",
                        value: "ACTIVE",
                      },
                      {
                        label: "Идэвхгүй",
                        value: "INACTIVE",
                      },
                    ]}
                    name="branchStatus"
                    label="Салбарын төлөв"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <Input.Wrapper label="Үндсэн эсэх">
                    <SwitchField name="isDefault" />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Input.Wrapper label="Профайлд гарах баннер зураг:">
                    {(() => {
                      const image = data?.profileBanners.find((i: any) => i.selected || i.isMain);
                      return image ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: 5,
                            overflow: "hidden",
                          }}
                          w={300}
                          my={10}>
                          <Image src={image?.url} width={300} height={200} fit="cover" />
                        </Box>
                      ) : (
                        <Box
                          w={300}
                          h={200}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: 5,
                          }}
                          my={10}>
                          <IconPhoto size={32} color={"gray"} />
                        </Box>
                      );
                    })()}
                    <Box>
                      <MultiImageUpload
                        initialData={values.profileBanners ?? []}
                        max={5}
                        onChange={(images) => {
                          setFormData((state) => ({ ...state, profileBanners: images }));
                          setFieldValue(
                            "profileBanners",
                            images.map((c: any) => ({
                              url: c.url,
                              isMain: c.selected,
                            })),
                          );
                        }}
                      />
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Input.Wrapper label="Лого зураг:">
                    <Flex direction="column" gap="sm">
                      <Box sx={{ position: "relative" }}>
                        {values.logo ? (
                          <Box style={{ border: "1px solid #e3e3e3", borderRadius: 5, overflow: "hidden" }} w={200}>
                            <Image height={150} alt="" src={values.logo} fit="cover" />
                          </Box>
                        ) : (
                          <Box
                            mt="sm"
                            w={200}
                            h={150}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                              border: "1px solid #e3e3e3",
                            }}>
                            <IconPhoto size={32} color={"gray"} />
                          </Box>
                        )}
                      </Box>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                        <ImageField name="logo" label="" />
                      </Grid.Col>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  <Flex direction="column" gap="md">
                    <Text fw={500}>1. DeHUB Network-р Buyer тохиргоо</Text>
                    <RadioGroupField
                      label="Салбарт худалдан авалт хийх эсэх"
                      name="isBuyer"
                      options={[
                        { label: "Тийм", value: "true" },
                        { label: "Үгүй", value: "false" },
                      ]}
                    />
                  </Flex>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  <Flex direction="column" gap="md">
                    <Text fw={500}>1. DeHUB Network-р Supplier тохиргоо</Text>
                    <RadioGroupField
                      label="Салбараас нийлүүлэлт хийх эсэх"
                      name="isSupplier"
                      options={[
                        { label: "Тийм", value: "true" },
                        { label: "Үгүй", value: "false" },
                      ]}
                    />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  {values.isBuyer === "true" ? (
                    <Box w="60%">
                      <MultiSelectField
                        options={businesses
                          ?.filter((d: any) => d?.type === "BUYER" && d?.businessStatus === "ACTIVE")
                          ?.map((c: any) => ({ label: `${c?.refCode} / ${c?.profileName}`, value: c?.id }))}
                        name="buyers"
                        label="Buyer бизнес Ref#"
                        placeholder="Buyer бизнес Ref#"
                      />
                    </Box>
                  ) : null}
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  {values.isSupplier === "true" ? (
                    <Box w="60%">
                      <MultiSelectField
                        options={businesses
                          ?.filter((d: any) => d?.type === "SUPPLIER" && d?.businessStatus === "ACTIVE")
                          ?.map((c: any) => ({ label: `${c?.refCode} / ${c?.profileName}`, value: c?.id }))}
                        name="suppliers"
                        label="Supplier бизнес Ref#"
                        placeholder="Supplier бизнес Ref#"
                      />
                    </Box>
                  ) : null}
                </Grid.Col>
              </Grid>
              <Divider />
              <Flex direction="row" justify="space-between">
                <Button
                  onClick={async () => {
                    const isValid = await formRef.current?.submit();
                    if (isValid) {
                      await onSubmit(isValid);
                      await reload();
                      onClose();
                    }
                  }}
                  color="teal">
                  Хадгалах
                </Button>
                <Button
                  onClick={async () => {
                    const isValid = await formRef.current?.submit();
                    if (isValid) {
                      await onSubmit(isValid);
                      setActiveTab("address");
                    }
                  }}
                  variant="outline"
                  rightSection={<IconChevronsRight />}
                  color="teal">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
