import { Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  options: { value: string; label: string }[];
  required?: boolean;
  itemComponent?: any;
  searchable?: boolean;
};

export function SelectField({ name, label, placeholder, onChange: onChangeValue, options = [], disabled = false, required, itemComponent, searchable }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Select
      component={itemComponent}
      label={label}
      searchable={searchable}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={(value) => {
        onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      nothingFoundMessage="Сонголт байхгүй байна"
      required={required}
      clearable
      rightSection={<IconChevronDown size={16} color="gray" />}
      data={options}
      style={{ flex: 1 }}
    />
  );
}
