import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const init = async () => {
  return httpRequest.get("/prt/pub/general/init");
};

export const check = async (data: any) => {
  return httpRequest.get("/prt/pub/general/init", data);
}; // regNumber

export const register = async (data: any) => {
  return httpRequest.post("/prt/pub/partner", data);
};
