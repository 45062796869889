import { Button, Divider, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { BusinessSectorApi, GeneralApi } from "../../apis";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";

type IFormData = {
  name: string | undefined;
  parentId: string | null | undefined;
};

const schema = yup.object({
  name: yup.string().trim().required("Заавал бөглөнө!"),
  parentId: yup.string().trim().nullable(),
});

export function BusinessSectorForm({ onClose, action, sectors }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const [data] = React.useState<IFormData>({
    name: undefined,
    parentId: null,
    ...(action && action[1] ? { parentId: action[1] } : null),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await BusinessSectorApi.create(values);
      const res = await GeneralApi.init();
      dispatch(initGeneral(res));

      message.success("Таны хүсэлт амжилттай!");
      setLoading(false);
      onClose();
    } catch (error: any) {
      setLoading(false);

      message.error(error?.message!);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <LoadingOverlay visible={loading} />
            <>
              {action[1] ? (
                <SelectField
                  disabled
                  name="parentId"
                  label="Секторын нэр"
                  placeholder=""
                  options={sectors?.map((c: any) => ({ label: c.name, value: c.id }))}
                />
              ) : null}
            </>
            <TextField
              name="name"
              label={action[1] ? "Дэд секторын нэр" : "Секторын нэр"}
              placeholder={action[1] ? "Дэд секторын нэр оруулна уу" : "Секторын нэр оруулна уу"}
            />
            <Divider />
            <Group align="flex-end">
              <Button type="submit">Хадгалах</Button>
            </Group>
          </Flex>
        );
      }}
    </Form>
  );
}
