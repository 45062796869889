import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const updateInfo = async (data: any) => {
  return httpRequest.put("/prt/partner", data);
};

export const list = async (data: any) => {
  return httpRequest.get("/prt/user", data);
};

export const updateAdmin = async (data: any) => {
  return httpRequest.put("/prt/partner/update_admin", data);
};

export const select = async (data: any) => {
  return httpRequest.get("/prt/user/select", data);
};

export const create = async (data: any) => {
  return httpRequest.post("/prt/user", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/prt/user/${id}`, data);
};

export const del = async (id: string) => {
  return httpRequest.del(`/prt/user/${id}`);
};
