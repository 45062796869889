import { IFilter } from "../interfaces/IFilter";
import { IPartner } from "../interfaces/IPartner";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const get = async (id: string) => {
  return httpRequest.get(`/prt/business/${id}`);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/prt/business", { offset, filter });
};

export const update = async (id: string, data: IPartner | any) => {
  return httpRequest.put(`/prt/business/${id}`, data);
};

export const profile = async (id: string, data: IPartner | any) => {
  return httpRequest.put(`/prt/business/${id}/profile`, data);
};

export const create = async (data: IPartner | any) => {
  return httpRequest.post(`/prt/business`, data);
};
