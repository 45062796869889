import { MultiSelect } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string[] | null) => void;
  options: { value: string; label: string }[];
};

export function MultiSelectField({ name, label, placeholder, onChange: onChangeValue, options, disabled = false }: Props) {
  const { getValue, error, onChange } = useField(name);

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder}
      value={getValue(name)}
      disabled={disabled}
      onChange={(values) => {
        onChange(values);

        onChangeValue && onChangeValue(values);
      }}
      rightSection={<IconChevronDown size={16} color="gray" />}
      error={error}
      nothingFoundMessage="Сонголт байхгүй байна"
      required
      clearable
      data={options}
      style={{ flex: 1 }}
    />
  );
}
