import { DatePickerInput, DateValue } from "@mantine/dates";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  icon?: any;
  nullable?: boolean;
  minDate?: any;
  maxDate?: any;
  readOnly?: boolean;
};

export function DatePickerField({ name, label, placeholder, readOnly = false, disabled = false, icon, nullable, minDate, maxDate }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <DatePickerInput
      readOnly
      maxDate={maxDate}
      minDate={minDate}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      valueFormat="MM-DD-YYYY"
      error={error}
      clearable={false}
      rightSection={icon}
      value={value ? new Date(value) : null}
      onChange={(e: DateValue) => {
        if (e) {
          onChange(new Date(e));
        } else {
          onChange(null);
        }
      }}
      required={nullable ? false : true}
    />
  );
}
