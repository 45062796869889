import { Flex, Image, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import { RegisterHeader } from "./register-header";

export const RegisterLayout = ({ children }: { children: any }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Image className={classes.background} src={Background} />
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <RegisterHeader />
      <div className={classes.body}>
        <div className={classes.child}>{children}</div>
      </div>
      <div className={classes.footerText}>
        <Flex align={"center"} justify="space-between" my="md">
          <Text c="dimmed">© {new Date().getFullYear()} Дижитал Эра Пэймэнт ХХК</Text>
        </Flex>
      </div>
    </div>
  );
};

const useStyles = createStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#051139",
    position: "relative",
  },
  child: {
    position: "relative",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  footerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    color: "white",
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
}));

export default RegisterLayout;
