import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBuildingWarehouse, IconCashBanknote, IconHome2, IconId, IconReceipt, IconTruck } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import { useScreenDetector } from "../screen-detector";

const items = [
  { title: "Партнер профайл", icon: IconId, color: "teal", link: "/organization", disabled: false },
  { title: "Манай нийлүүлэлт", icon: IconTruck, color: "teal", link: "/our-supplier", disabled: false },
  { title: "Манай худалдан авалт", icon: IconCashBanknote, color: "teal", link: "/our-buyer", disabled: false },
  { title: "Манай салбарууд", icon: IconHome2, color: "teal", link: "/our-sectors", disabled: false },
  { title: "Манай агуулахууд", icon: IconBuildingWarehouse, color: "teal", link: "/our-warehouse", disabled: false },
];

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const { isMobile } = useScreenDetector();

  return (
    <AppShell.Navbar style={{ padding: "1rem" }}>
      {items.map((item, index) => (
        <NavItem item={item} key={index} setOpened={setOpened} opened={isMobile && opened} />
      ))}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, setOpened, opened = false }: { item: INavItem; setOpened: (e: boolean) => void; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "teal" });

  return (
    <CommingSoon visible={item.disabled}>
      <NavLink
        className={({ isActive }) => cx(classes.item, item.disabled && "disabled", opened && classes.row, isActive && "active")}
        to={!item.disabled ? item.link : "#"}
        onClick={() => {
          setOpened(false);
        }}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={opened ? 0 : 7} lh="16px">
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const CommingSoon = ({ children, visible = false }: { children: JSX.Element; visible?: boolean }) => {
  if (!visible) return children;

  return null;
};

const useStyles = createStyles((theme, { color }: { color: string }, u) => {
  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "1rem",
      [`&:last-child`]: {
        marginBottom: 0,
      },
      [`&.disabled .${u.ref("icon")}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${u.ref("icon")}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${u.ref("icon")}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`@media screen and (max-width: 1024px)`]: {
        flexDirection: "row",
        justifyContent: "start",
        gap: 10,
      },
    },
    icon: {
      ref: u.ref("icon"),
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
    row: {
      display: "flex",
      gap: 15,
      justifyContent: "start",
      borderRadius: theme.radius.sm,
      [`&:hover`]: {
        background: theme.colors[color][0],
        transform: "revert",
        animation: "ease-in",
      },
    },
  };
});
