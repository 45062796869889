import { Alert, Box, Button, Collapse, Divider, Flex, Grid, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBuilding, IconFileInvoice, IconId, IconMail, IconMapPin, IconPhone, IconUser, IconWorld } from "@tabler/icons-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthApi, PartnerApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { AdminForm } from "../../components/partner/admin-form";
import { MainInfoForm } from "../../components/partner/mainInfo-form";
import StarMap from "../../components/star";
import { ImageUpload } from "../../components/upload/image-upload";
import { IAuth } from "../../interfaces/IAuth";
import { authMe } from "../../store/Auth";
import { message } from "../../utils/message";

export function Partner() {
  const breadcrumbs = useBreadcrumb();
  const { classes } = useStyles();
  const [preAlert, setPreAlert] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>("info");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const dispatch = useDispatch();

  const changeLogo = async (e: any) => {
    try {
      await PartnerApi.uploadLogoProfile({ logo: e?.url });
      const auth = await AuthApi.me();
      dispatch(authMe(auth));
      message.success("Партнерийн зураг амжилттай солигдлоо");
    } catch (error: any) {
      message.error(error.message ?? "Алдаа гарлаа");
    }
  };

  return (
    <PageLayout title="Партнер профайл" breadcrumb={breadcrumbs} extra={[]}>
      <Flex direction="column" gap="md">
        <Collapse in={preAlert}>
          <Alert
            styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
            onClose={() => setPreAlert(false)}
            radius="md"
            color="red"
            withCloseButton
            title="Анхаар!">
            Та Дижитал Эра Пэймэнт ХХК-тай байгуулсан гэрээнд холбогдох мэдээллийг өөрчлөх боломжгүй. Мэдээлэл буруу байвал ДЭП ХХК-д хандана уу.
          </Alert>
        </Collapse>

        <Grid gutter="md">
          <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 4 }}>
            <Box px={20} py={16} className={classes.gridBox}>
              <Flex align="center" direction="column" gap="md" justify="center">
                <Box>
                  <ImageUpload value={user?.partner?.logo} radius={100} size={87} onChange={(e) => changeLogo(e)} />
                </Box>
                <Text fw={600} fz="xl" c="teal">
                  {user?.partner?.businessName ?? "-"}
                </Text>
                <Text fw={700} fz="sm" c="gray">
                  ТТД: {user?.partner?.regNumber ?? "-"}
                </Text>
                <div className={classes.stars}>
                  <StarMap reviewScore={user?.partner?.reviewScore ?? 0} /> {user?.partner?.reviewScore > 0 ? `(${user?.partner?.reviewScore ?? 0})` : null}
                </div>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Холбоо барих" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"} mt={12}>
                    {user?.partner?.web && (
                      <div className={classes.preInfo}>
                        <div className={classes.preInfoIcon}>
                          <IconWorld />
                        </div>
                        <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                          {user?.partner?.web ?? "-"}
                        </Text>
                      </div>
                    )}
                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconMail />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {user?.partner?.email ?? "-"}
                      </Text>
                    </div>
                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconPhone />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {user?.partner?.phone ?? "-"}
                      </Text>
                    </div>

                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconUser />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {user?.departmentUnit?.name ?? "-"}
                      </Text>
                    </div>

                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconId />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {user?.employeeUnit?.name ?? "-"}
                      </Text>
                    </div>

                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconMapPin />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {user?.partner?.businessAddress ?? "-"}
                      </Text>
                    </div>
                  </Flex>
                </Box>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Buyer бизнес" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"}>
                    {user?.buyers?.length > 0 ? (
                      user?.buyers?.map((item: any, index: number) => (
                        <div key={index} className={classes.preInfo}>
                          <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                            {item.profileName}
                          </Text>
                        </div>
                      ))
                    ) : (
                      <div className={classes.preInfo}>
                        <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                          Бүртгээгүй
                        </Text>
                      </div>
                    )}
                  </Flex>
                </Box>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Supplier бизнес" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"}>
                    {user?.suppliers?.length > 0 ? (
                      user?.suppliers?.map((item: any, index: number) => (
                        <div key={index} className={classes.preInfo}>
                          <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                            {item.profileName ?? "-"}
                          </Text>
                        </div>
                      ))
                    ) : (
                      <div className={classes.preInfo}>
                        <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                          Бүртгээгүй
                        </Text>
                      </div>
                    )}
                  </Flex>
                </Box>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Нэмэлт мэдээлэл" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"} mt={12}>
                    <Button leftSection={<IconFileInvoice />} onClick={() => setActiveTab("agreement")} className={classes.preInfoButton} disabled>
                      <Text lineClamp={3} fw={500} fz={16} ta="start">
                        Гэрээ, бичиг баримт
                      </Text>
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 7, xl: 8 }}>
            <Box px={20} py={16} className={classes.gridBox}>
              <Tabs h="100%" variant="pills" value={activeTab} onChange={(value: any) => setActiveTab(value)}>
                <Tabs.List>
                  <Tabs.Tab leftSection={<IconBuilding color={activeTab === "info" ? "white" : "gray"} />} value="info">
                    <Text c={activeTab === "info" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
                  </Tabs.Tab>
                  <Tabs.Tab value="admin" leftSection={<IconUser color={activeTab === "admin" ? "white" : "gray"} />}>
                    <Text c={activeTab === "admin" ? "" : "gray"}>Админ хэрэглэгч</Text>
                  </Tabs.Tab>
                </Tabs.List>
                <Divider mt="md" mb="md" />
                <Tabs.Panel value="info">
                  <MainInfoForm setActiveTab={setActiveTab} />
                </Tabs.Panel>
                <Tabs.Panel h="100%" value="admin">
                  <AdminForm setActiveTab={setActiveTab} />
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Grid.Col>
        </Grid>
      </Flex>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Партнер",
  },
];

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },
  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
  systemUserBtn: {
    height: "48px",
  },
  stars: { display: "flex", alignItems: "center", color: "#868e96", fontWeight: 700, fontSize: 14 },
  adminIcon: {
    color: theme.colors.red[8],
  },
  preInfo: {
    display: "flex",
    width: "100%",
    gap: 12,
    color: theme.colors.gray[6],
  },
  preInfoIcon: {
    height: 24,
    width: 24,
    padding: 0,
  },
  preInfoButton: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    width: "100%",
    gap: "12px",
    cursor: "pointer",
    color: theme.colors.gray[6],
    backgroundColor: "white",
    "&:hover": {
      color: theme.colors.teal[8],
      backgroundColor: "white",
    },
  },
}));
